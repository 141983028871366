$radius-big: 36px;
$radius-small: 24px;

/* colors */
$purple: #4f3167;
$purple2: #6b0d84;
$yellow-gold: #f1d189;
$yellow-gold-light: #f7e9c9;
$yellow-gold-dark: #916400;
$yellow-gold-darkest: #624400;
$background-black: #0d0c11;

/* sizes */
$play-icon-big: 162px;
$play-icon-small: ($play-icon-big/2);
