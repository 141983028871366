%container {
    width: 1140px;
    margin: 0 auto;
    color: white;
    position: relative;
}

%paly-icon-before-after {
    transition: 0.2s;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 1;
}

@mixin play-button($play-icon-size) {
    position: absolute;
    width: $play-icon-small;
    height: $play-icon-small;
    z-index: 1;
    &::after {
        @extend %paly-icon-before-after;
        background: url(../img/play-buttons.svg) top center no-repeat;
        background-position: -$play-icon-small 0;
        background-size: 200%;
        opacity: 0;
    }
    &::before {
        @extend %paly-icon-before-after;
        background: url(../img/play-buttons.svg) top center no-repeat;
        background-position: 0 0;
        background-size: 200%;
        opacity: 1;
    }
}

body {
    color: black;
    background: $background-black;
    font-family: 'GardensC';
    font-size: 20px;
    font-weight: 300;
}

/* for iOS jquey-modal proper scrolling */
body,
html {
    -webkit-overflow-scrolling: touch !important;
    overflow: auto !important;
}

.container {
    @extend %container;
}

h1,
.h1 {
    font-family: 'Port-Arthur';
    text-align: center;
    font-size: 120px;
    color: $yellow-gold;
    font-weight: 100;
    margin-top: 0;
    padding-top: 0.5em;
}

h2,
.h2 {
    font-weight: 600;
    text-align: center;
    font-size: 66px;
    margin-top: 0;
    padding-top: 60px;
    text-shadow: 3px 3px 0 $yellow-gold-dark;
}

h3,
.h3 {
    font-weight: 600;
    text-align: center;
    font-size: 36px;
    margin: 64px 0 24px 0;
    color: $yellow-gold;
}

.page-background {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
    z-index: -1;
}

.button {
    display: block;
    background: #f1d189;
    text-align: center;
    width: fit-content;
    margin: 0 auto;
    padding: 12px 24px;
    border-radius: 30px;
    border: 0;
    color: $yellow-gold-darkest;
    cursor: pointer;
}

.contacts {
    font-size: 40px;
    a,
    a:active,
    a:visited {
        color: white;
        text-decoration: none;
        &:hover {
            opacity: 0.8;
        }
    }
    i {
        margin-right: 16px;
        font-style: normal;
        vertical-align: top;
        .fab {
            font-size: 35px;
        }
        span {
            font-size: 54px;
        }
    }
}

.lightgallery-video {
    .youtube-play-btn-containter {
        z-index: 9999999;
        position: absolute;
        top: 39%;
        text-align: center;
        width: 100%;
        .youtube-play-btn {
            width: 96px !important;
        }
    }
    &__youtube-link {
        overflow: hidden;
        display: flex;
        width: 45%;
        height: 285px;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        background-position: center;
        background-size: cover;
        border-radius: $radius-big;
        box-shadow: 0 0 40px 0px rgba(255, 255, 255, 0.5);
        &:hover {
            box-shadow: 0 0 60px 0px #ffe9b580;
            transition: 0.2s;
            .lightgallery-video__play-button {
                &::after {
                    opacity: 1;
                }
                &::before {
                    opacity: 0;
                }
            }
        }

        img {
            width: 100%;
        }
    }
    &__play-button {
        @include play-button($play-icon-big);
    }

    .feedbacks {
        &__youtube-link {
            width: 100%;
            height: 195px;
            box-shadow: none;
            &:hover {
                box-shadow: 0 0 20px 0px rgba(255, 255, 255, 0.5);
            }

            img {
                @media (max-width: 767px) {
                    width: 235%;
                }
            }
        }
        &__play-button {
            @include play-button($play-icon-small);
        }
    }
}

.lightgallery-image {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    &__link {
        margin: 6px 12px;
        border-radius: $radius-big;
        overflow: hidden;
        line-height: 0;
        &:hover {
            box-shadow: 0 0 60px 0px #ffe9b580;
            transition: 0.2s;
        }
    }
    &__button {
        display: none;
        margin-top: 12px;
        width: 100%;
    }
}

.topbar {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    padding: 16px 24px;
    background-color: hsla(0, 0%, 0%, 0.5);
    justify-content: space-between;
    font-size: 38px;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    backdrop-filter: blur(7px);
    z-index: 2;
    
    &__socialicons,
    &__phone {
        a,
        a:active,
        a:visited {
            color: white;
            text-decoration: none;
            &:hover {
                opacity: 0.8;
            }
        }
        i {
            margin-right: 6px;
            font-style: normal;
            vertical-align: top;
            .fab {
                font-size: 35px;
            }
            span {
                font-size: 54px;
            }
        }
    }
}

.greeting {
    text-align: center;

    b {
        color: $yellow-gold;
        margin: 36px 0 0;
        display: block;
    }
}

.advantages {
    display: flex;
    flex-wrap: wrap;

    &__item {
        width: 50%;
        box-sizing: border-box;
        padding: 24px;
        text-align: center;

        @media (max-width: 767px) {
            width: 100%;
            box-sizing: border-box;
            padding: 6px;
            text-align: center;
            &::after {
                content: "●";
                display: block;
                margin-top: 12px;
                color: $yellow-gold;
            }
        }
    }
}

.header {
    font-family: 'Port-Arthur';
    height: 100vh;
    background: url(../img/header.jpg) top center no-repeat;
    color: white;
    padding-top: 64px;
    position: relative;
    background-color: #0d0c11;
    box-shadow: 0 0 200px 200px #0d0c11ff;
    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30%;
        background: linear-gradient(0deg, #0d0c11ff 0%, #0d0c1100 100%);
    }
    &__title {
        display: block;
        font-size: 112px;
        font-weight: 100;
        margin: 0;
        line-height: 112px;
        letter-spacing: 9px;
        text-shadow: 3px 3px 0px rgba(0, 0, 0, 0.2);
        padding: 32px 0 0 0;
        color: $yellow-gold;
        text-decoration: none;
    }
    &__name {
        font-size: 60px;
        color: $yellow-gold-light;
    }
    &__text {
        // font-family: 'Kobzar KS';
        width: 428px;
        font-weight: 100;
        font-size: 38px;
        line-height: 31px;
        margin: 17px 0 44px 0;
        color: white;
        transform: skewY(-5deg);
    }
    &__lightgallery-video {
        margin-top: 100px;
    }
    &__youtube-link {
        background-image: url(https://i.ytimg.com/vi/6FqWy7z249U/maxresdefault.jpg);
        background-size: 1000px auto;
    }
}

.adult {
    .header {
        background: url(../img/adult_bkg.jpg) top center no-repeat #0c0b10;
        background-size: 100%;
    }
}

.kids {
    .header {
        background: url(../img/kids_bkg_0.jpg) top center no-repeat #0c0b10;
        background-size: 100%;
    }
}

@media (max-width: 767px) {
    .header {
        padding-top: 48px;
        .container {
            margin-top: 65vw;
        }
    }
    

    .adult {
        .header {
            // background-size: 300vw;
            // background-position: -145vw -20vw;
            background-size: 220vw;
            background-position: -95vw 5vw;
            position: relative;
            height: 205vw;

            &::before {
                top: 77vw;
                height: 70%;
                background: linear-gradient(0deg, #0d0c11ff 65%, #0d0c1100 100%);
            }
        }
    }
    
    .kids {
        .header {
            // background-size: 300vw;
            // background-position: -185vw 0;
            background-size: 220vw;
            background-position: -120vw 45px;
            position: relative;
            height: 205vw;

            &::before {
                top: 77vw;
                height: 70%;
                background: linear-gradient(0deg, #0d0c11ff 65%, #0d0c1100 100%);
            }
        }
    }
}

@media (max-width: 375px) {
    .adult,
    .kids {
        .header {
            height: 180vw;
            .container {
                margin-top: 58vw;
                
                .header__title {
                    font-size: 20vw;
                }

                .contacts {
                    font-size: 30px;
                }
            }
        }
    }
}

@media (max-width: 375px) {
    .adult,
    .kids {
        .header {
            height: 195vw;
        }
    }
}

.menu-programs {
    display: flex;
    position: fixed;
    z-index: 10;
    font-family: 'GardensC';
    justify-content: center;
    background: #f6e5be;
    padding: 12px 6px;
    margin: 0 auto;
    border-radius: 90px 90px 0 0;
    bottom: 0;
    left: 50%;
    margin-left: -225px;
    width: 450px;
    box-shadow: 0 0 20px 9px #f6e5be7d;
    &__item {
        text-decoration: none;
        display: block;
        background: #c33a38;
        color: white;
        padding: 12px 24px;
        margin: 0 6px;
        border-radius: 50px;
        width: 140px;
        text-align: center;
        font-weight: bold;
        transition: 0.5s;
        &:hover {
            background: #ec605d;
        }
        &:active {
            transition: 0;
            background: #f58987;
        }
    }
}

.program {
    .content-help-text {
        font-size: 0.8em;
        color: #ccc;
        margin-bottom: 1rem;
    }
    
    
    .content-highlight {
        color: #f1d189;
        font-size: 1.8em;
        transform: rotateZ(-5deg);
        display: inline-block;
        font-weight: 700;
    }

    &__teaser-text {
        text-align: center;
    }
    &__desctiption {
        text-align: left;
    }
    &__modal {
        background: #f4f4f4;
        color: black;
        max-width: 800px;
        padding: 48px;
        border-radius: 32px;
        box-shadow: 0 0 86px 6px #f4f4f4;
        .h2 {
            color: $yellow-gold-darkest;
            margin-top: 0;
            text-shadow: none;
        }
        .content-help-text {
            color: #e29c00;
        }

        @media (max-width: 767px) {
            & > .program__description {
                max-height: 38vh;
                overflow-y: scroll;
            }
        }
    }
    &__videos {
        display: flex;
        flex-wrap: wrap;
    }
    &__youtube-link {
        width: 48%;
        margin: 12px auto;
    }
    & > .main_image__wrapper {
        text-align: center;

        & > .main_image {
            border-radius: $radius-big;
            box-shadow: 0 0 40px 0px rgba(255, 255, 255, 0.5);

            @media (max-width: 767px) {
                width: 80%;
                height: auto;
            }

            @media (max-width: 600px) {
                width: 90%;
                height: auto;
            }
        }
    }
}

.feedbacks-videos {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 24px;
    row-gap: 24px;

    @media (max-width: 767px) {
        grid-template-columns: 1fr 1fr;
    }
}

.footer {
    height: 120px;
}

.fancybox-slide {
    padding: 0 !important;
}

.fancybox-bg {
    background: rgba(0, 0, 0, 0.6);
}
