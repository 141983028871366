@media (max-width: 767px) {
	%container {
		width: 100%;
		box-sizing: border-box;
		padding: 0 12px;
	}

	h1,
	.h1 {
		font-size: 25vw;
		line-height: 0.7;
		margin-bottom: 0;
	}

	h2,
	.h2 {
		font-size: 12vw;
		text-shadow: 2px 2px 0 $yellow-gold-dark;
	}

	h3,
	.h3 {
		font-size: 8vw;
	}

	.contacts {
		i {
			margin-right: 6px;
		}
	}

	.lightgallery-video {
		&__youtube-link {
			width: 90%;
			height: 46vw;
			background-size: 100vw;
		}
	}

	.lightgallery-image {
		&__link {
			display: none;
		}

		@for $i from 1 through 4 {
			&__link:nth-child(#{$i}) {
				display: block;
				width: 47%;
				height: 100px;
				position: relative;
				margin: 3px;
				border-radius: $radius-small;
				img {
					width: 100%;
				}
			}
		}

		&__link:nth-child(4)::after {
			content: attr(image-amount);
			position: absolute;
			left: 0;
			bottom: 0;
			line-height: 100px;
			font-size: 18px;
			font-weight: 600;
			color: black;
			width: 100%;
			text-align: center;
			height: 100%;
			background: rgba(246, 229, 190, 0.7);
		}

		&__button {
			display: block;
		}
	}

	.header {
		&__title {
			font-size: 27vw;
			line-height: 1;
			letter-spacing: 0;
		}

		&__text {
			width: 100%;
		}

		&__lightgallery-video {
			margin-top: 50px;
		}
	}

	.background {

		&-kids {
			box-shadow: none;
		}

		&-adults {
			box-shadow: none;
		}   
	}

	.main {
		&__container {
			background: linear-gradient(180deg, rgb(13, 12, 17) 0, rgba(13, 12, 17, 0) 200px);
		}
	}

	.program {
		&__modal {
			font-size: 16px;
			ul {
				padding: 0;
				li {
					padding: 6px 0;
				}
			}
			.h2 {
				color: $yellow-gold-darkest;
				margin-top: 0;
				padding-top: 0;
				text-shadow: none;
				font-size: 8vw;
			}	
		}
	}

	.menu-programs {
		padding: 12px 0;
		margin: 0;
		border-radius: 20px 20px 0 0;
		bottom: 0;
		left: 0;
		box-sizing: border-box;
		width: 100%;
		&__item {
			font-size: 14px;
			&:hover {
				background: #ec605d;
			}
		}
	}
}

@media (max-width: 367px) {
	.program {
		&__modal {
			padding: 24px;
		}
	}

	.menu-programs {
		&__item {
			font-size: 14px;
		}
	}    
}
