@font-face {
    font-family: 'GardensC';
    src: url('../fonts/GardensC.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Kobzar KS';
    src: url('../fonts/KobzarKS.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Port-Arthur';
    src:
        url('../fonts/Port-Arthur.woff2') format('woff2'),
        url('../fonts/Port-Arthur.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HarryPotter';
    src: url('../fonts/HarryPotter.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
