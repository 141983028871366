.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.swiper-slide {
    width: 300px;
    height: 300px;
    background-position: center;
    background-size: 210%;
    box-shadow: inset 0 0 68px 22px #000;
    color: #eee;
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 18px;
    border-radius: 80px;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 25px;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("https://i.ibb.co/tqNZYT7/bitmap1.png");
        background-position: top center;
        background-repeat: no-repeat;
        background-size: 50%;
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        top: -25px;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("https://i.ibb.co/tqNZYT7/bitmap1.png");
        background-position: top center;
        background-repeat: no-repeat;
        background-size: 50%;
        transform: rotateZ(180deg);
    }
}

.swiper-3d .swiper-slide-shadow-left {
    border-radius: 50px;
}

.swiper-3d .swiper-slide-shadow-right {
    border-radius: 50px;
}

.swiper-pagination-bullet {
    background: white;
}

.swiper-button-next,
.swiper-button-prev {
    color: white;
}
